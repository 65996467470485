import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <footer className="footer py-60">
            <img src="/assets/images/bg/body-bottom-bg.png" className="body-bottom-bg"/>
            <div className="container container-lg">
                <div className="footer-item-wrapper d-flex align-items-start flex-wrap">
                    <div className="footer-item text-gray-900">
                        <div className="footer-item__logo">
                            <Link to="/"><img src="/assets/images/logo/logo.png" /></Link>
                        </div>
                        <p className="mb-24 text-sm" style={{textAlign: 'justify'}}>
                            Энх-Ингүмэл ХХК-ийн албан ёсны веб хуудсанд<br/>тавтай морилно уу.
                        </p>
                        <div className="flex-align gap-16 mb-16" style={{textAlign: 'justify'}}>
                            <span className="w-32 h-32 flex-center rounded-circle bg-main-600 text-white text-sm flex-shrink-0">
                                <i className="ph-fill ph-map-pin" />
                            </span>
                            <span className="text-sm">
                                Улаанбаатар хот, Баянгол дүүрэг, 7-р хороо, 29-217 тоот
                            </span>
                        </div>
                        <div className="flex-align gap-16 mb-16">
                            <span className="w-32 h-32 flex-center rounded-circle bg-main-600 text-white text-sm flex-shrink-0">
                                <i className="ph-fill ph-phone-call" />
                            </span>
                            <div className="flex-align gap-16 flex-wrap">
                                <Link to="/tel:+97677038585" className="text-sm text-gray-900 hover-text-main-600">
                                    +976 7703-8585
                                </Link>
                                <span className="text-sm text-main-600 ">|</span>
                                <Link to="/tel:+97695106392" className="text-sm text-gray-900 hover-text-main-600">
                                    +976 9510-6392
                                </Link>
                                <span className="text-sm text-main-600 ">|</span>
                                <Link to="/tel:+97680606392" className="text-sm text-gray-900 hover-text-main-600">
                                    +976 8060-6392
                                </Link>
                            </div>
                        </div>
                        <div className="flex-align gap-16 mb-16">
                            <span className="w-32 h-32 flex-center rounded-circle bg-main-600 text-white text-sm flex-shrink-0">
                                <i className="ph-fill ph-envelope" />
                            </span>
                            <Link to="/mailto:ingumel0105@gmail.com" className="text-sm text-gray-900 hover-text-main-600">
                                ingumel0105@gmail.com
                            </Link>
                        </div>
                    </div>
                    <div className="footer-item text-gray-900">
                        <h6 className="footer-item__title text-lg">Ухаалаг апплекшн</h6>
                        <p className="mb-16 text-sm">Тун удахгүй бидний апплекшн бэлэн болох тул<br/>доорх платформууд дээр татаж аваарай.</p>
                        <div className="flex-align gap-8 my-32">
                            <Link to="/https://www.apple.com/store">
                                <img src="/assets/images/thumbs/store-img1.png" />
                            </Link>
                            <Link to="/https://play.google.com/store/apps?hl=en">
                                <img src="/assets/images/thumbs/store-img2.png" />
                            </Link>
                        </div>
                        <ul className="flex-align gap-16">
                            <li>
                                <Link to="https://www.facebook.com/profile.php?id=100066856784352" className="w-44 h-44 flex-center bg-main-100 text-main-600 text-xl rounded-circle hover-bg-main-600 hover-text-white">
                                    <i className="ph-fill ph-facebook-logo" />
                                </Link>
                            </li>
                            <li>
                                <Link to="https://www.facebook.com/groups/690764772903614" className="w-44 h-44 flex-center bg-main-100 text-main-600 text-xl rounded-circle hover-bg-main-600 hover-text-white">
                                    <i className="ph-fill ph-facebook-logo" />
                                </Link>
                            </li>
                            <li>
                                <Link to="" className="w-44 h-44 flex-center bg-main-100 text-main-600 text-xl rounded-circle hover-bg-main-600 hover-text-white">
                                    <i className="ph-fill ph-x-logo" />
                                </Link>
                            </li>
                            <li>
                                <Link to="" className="w-44 h-44 flex-center bg-main-100 text-main-600 text-xl rounded-circle hover-bg-main-600 hover-text-white">
                                    <i className="ph-fill ph-instagram-logo" />
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>

    )
}

export default Footer