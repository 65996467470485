import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Preloader from "../helper/Preloader";
import ScrollToTop from "react-scroll-to-top";
import BottomNav from "../components/BottomNav";
import BottomFooter from "../components/BottomFooter";
import ProductDetails from "../components/ProductDetails";

const ProductDetailsPage = () => {
  const [cartCount, setCartCount] = useState(localStorage.cartLength || 0);
  const addToCartHeader = () => {
      const currentQuantity = Number(cartCount);
      setCartCount(currentQuantity + 1);
  };

  return (
    <>
      <Preloader />

      <ScrollToTop smooth color="#0771a6" />

      <Header cartCount={cartCount}/>

      <ProductDetails addToCartHeader={addToCartHeader}/>

      <BottomNav />

      <Footer />

      <BottomFooter />
    </>
  );
};

export default ProductDetailsPage;