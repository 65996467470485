export const apiRequest = async (path, method, body) => {    
    // const baseurl = '/api/';
    const baseurl = process.env.REACT_APP_API_URL + '/api/';

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
    };

    const options = {
        method,
        headers,
        body: body ? JSON.stringify(body) : null,
    };

    try {
        const response = await fetch(`${baseurl}${path}`, options);
        return await response.json();
    } catch (error) {
        console.error('API request error:', error);
        throw error;
    }
};

export const GetRequest = async (path) => {
    return apiRequest(path, 'GET');
};

export const PostRequest = async (path, body) => {
    return apiRequest(path, 'POST', body);
};