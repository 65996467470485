import React, { useState, useContext } from "react";
import toast, { Toaster } from 'react-hot-toast';
import { PostRequest } from "../helper/ApiConfig";
import { AuthContext } from '../helper/auth-context';

import 'primeicons/primeicons.css';

const Login = () => {
    const { login } = useContext(AuthContext);
    const [ loading, setLoading ] = useState(false);
    const [ showPassword, setShowPassword ] = useState(false);
    const [ userPass, setUserPass ] = useState(localStorage.getItem('password') || '');
    const [ userPhone, setUserPhone ] = useState(localStorage.getItem('userPhone') || '');
    const [ rememberMe, setRememberMe ] = useState(localStorage.getItem('rememberMe') == 'true');

    const handleRememberMe = () => {
        if (rememberMe) {
            localStorage.setItem('userPhone', userPhone);
            localStorage.setItem('password', userPass);
            localStorage.setItem('rememberMe', true);
        } else {
            localStorage.removeItem('userPhone');
            localStorage.removeItem('password');
            localStorage.removeItem('rememberMe');
        }
    };

    const handlePhoneInput = (e) => {
        let phValue = e.target.value;
        if(phValue.substr(0, 4) == '+976') {
            let strValue = phValue.substr(0, 4);
            setUserPhone(strValue.replace(/\D/g, ''));
        }
            
        const value = e.target.value.replace(/\D/g, '');
        setUserPhone(value);
    };

    const submitHandler = async (event) => {
        event.preventDefault();
        handleRememberMe();
        setLoading(true);

        try {
            const response = await PostRequest('user/login', { user_phone: userPhone, user_pass: userPass });

            if (response.status == 200) {
                const userInfo = response.user_info;
                login(userInfo.cust_name, userInfo.cust_phone, userInfo.id);
                Object.entries({
                    userName: userInfo.cust_name,
                    phoneNumber: userInfo.cust_phone,
                    regNum: userInfo.cust_regNum,
                    userId: userInfo.id,
                    userSoumName: userInfo.soum_name,
                    userCityName: userInfo.city_name,
                    userSoumId: userInfo.soum_id,
                    userCityId: userInfo.prov_id,
                    userFB: userInfo.cust_fb,
                    userPass: userInfo.cust_pass
                }).forEach(([key, value]) => localStorage.setItem(key, value));

                window.location.href = '/';
            }
        } catch (error) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <section className="account py-80">
            <Toaster position="top-right" />
            <div className="container container-lg">
                <form onSubmit={submitHandler}>
                    <div className="row gy-4">
                        <div className="col-xl-6 pe-xl-5">
                            <div className="border border-gray-100 hover-border-main-600 transition-1 rounded-16 px-24 py-40 h-100">
                                <h6 className="text-lg mb-16">Нэвтрэх</h6>
                                <span className="mb-32 text-justify text-sm">
                                    Та захиалга өгөх, барааны жагсаалт харахын тулд өөрийн эрхээр нэвтэрч орсон байх ёстойг анхаарна уу.
                                </span>
                                <div className="mb-20">
                                    <label className="text-neutral-900 text-sm mb-8 fw-medium">
                                        Утасны дугаар <span className="text-danger">*</span>
                                    </label>
                                    <input 
                                        value={userPhone} 
                                        onChange={handlePhoneInput} 
                                        type="text" 
                                        className="common-input" 
                                        placeholder="Утасны дугаараа оруулна уу..." 
                                        maxLength={8} 
                                        required />
                                    { userPhone.length > 0 && userPhone.length < 8 && (
                                        <label className="text-sm" style={{ color: 'red', fontStyle: 'italic' }}>
                                            Утасны дугаар буруу байна!
                                        </label>
                                    )}
                                </div>
                                <div className="mb-20">
                                    <label className="text-neutral-900 text-sm mb-8 fw-medium">
                                        Нууц үг <span className="text-danger">*</span>
                                    </label>
                                    <div className="password-wrapper" style={{ position: 'relative' }}>
                                        <input 
                                            value={userPass} 
                                            onChange={(e) => setUserPass(e.target.value)} 
                                            type={showPassword ? "text" : "password"} 
                                            className="common-input" 
                                            placeholder="Нууц үгээ оруулна уу..." 
                                            required 
                                        />
                                        <button 
                                            type="button" 
                                            onClick={() => setShowPassword(!showPassword)} 
                                            style={{ 
                                                position: 'absolute', 
                                                right: '10px', 
                                                top: '50%', 
                                                transform: 'translateY(-50%)', 
                                                background: 'none', 
                                                border: 'none', 
                                                color: '#0771a6',
                                                cursor: 'pointer' 
                                            }}>
                                            <i className={showPassword ? 'pi pi-eye-slash' : 'pi pi-eye'}></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="mb-20 mt-30">
                                    <input type="checkbox" checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} />
                                    <label className="text-sm">&nbsp;Нэвтрэх мэдээлэл сануулах </label>
                                </div>
                                <div className="mt-40">
                                    <button type="submit" className="btn btn-main py-18 px-40 text-sm" disabled={loading || userPhone.length == 0 || userPass.length == 0}>
                                        {loading ? 'Түр хүлээнэ үү...' : 'Нэвтрэх'}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="border border-gray-100 hover-border-main-600 transition-1 rounded-16 px-24 py-40">
                                <img src="/assets/images/bg/login.png" alt="login"/>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
};

export default Login;
