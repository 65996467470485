import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { AuthContext } from '../helper/auth-context';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
        
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/lara-light-blue/theme.css';

const Header = ({ cartCount }) => {
    const { logout } = useContext(AuthContext);
    const [ scroll, setScroll ] = useState(false)
    const [ visible, setVisible ] = useState(false);
    const [ cartItems, setCartItems ] = useState(0);
    const [ menuActive, setMenuActive ] = useState(false)
    const [ activeIndex, setActiveIndex ] = useState(null);

    const confirm = () => {
        logout();
        setVisible(false);
        window.location.reload(); 
    };
    
    const cancel = () => setVisible(false);

    useEffect(() => {
        let isMounted = true;

        if (!localStorage.firstLoadHandled) {
            localStorage.removeItem('cart');
            localStorage.setItem('firstLoadHandled', true);
        }

        const handleScroll = () => {
            if (window.pageYOffset < 150)
                setScroll(false);
            else if (window.pageYOffset > 150)
                setScroll(true);
        };
        
        localStorage.getItem('cart') && setCartItems(JSON.parse(localStorage.getItem('cart')).length) ;

        window.addEventListener('scroll', handleScroll);
        
        return () => {
            isMounted = false;
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const handleMenuToggle = () => {
        setMenuActive(!menuActive);
    };

    return (
        <>
            <div className={`mobile-menu scroll-sm d-lg-none d-block ${menuActive && "active"}`} >
                <button onClick={() => { handleMenuToggle(); setActiveIndex(null) }} type="button" className="close-button bg-main-300">
                    <i className="ph ph-x text-white" />{" "}
                </button>
                <div className="mobile-menu__inner">
                    <Link to="/" className="mobile-menu__logo">
                        <img src="/assets/images/logo/logo.png" />
                    </Link>
                    <div className="mobile-menu__menu">
                        <ul className="nav-menu flex-align nav-menu--mobile">
                            <li className="nav-menu__item">
                                <Link to="#" className="nav-menu__link text-center">
                                    <i className="pi pi-user text-2xl text-main" /><br/>
                                    <span className="text-md text-gray-500">{localStorage.userName}</span>
                                </Link>
                                <Link to="#" className="nav-menu__link text-center">
                                    <i className="pi pi-phone text-2xl text-main" /><br/>
                                    <span className="text-md text-gray-500">{localStorage.phoneNumber}</span>
                                </Link>
                                <Link to="#" className="nav-menu__link text-center">
                                    <i className="pi pi-info-circle text-2xl text-main" /><br/>
                                    <span className="text-md text-gray-500">{localStorage.regNum}</span>
                                </Link>
                            </li>
                            <li className="nav-menu__item">
                                <Link to="/account" className="nav-menu__link text-sm text-gray-500">
                                    <i className="pi pi-id-card text-main" />
                                    &nbsp;Хувийн мэдээлэл
                                </Link>
                            </li>
                            <li className="nav-menu__item">
                                <Link to="/invoice" className="nav-menu__link text-sm text-gray-500">
                                    <i className="pi pi-book text-main" />
                                    &nbsp;Миний нэхэмжлэлүүд
                                </Link>
                            </li>
                            <li className="nav-menu__item">
                                <Link onClick={() => setVisible(true)} className="nav-menu__link text-sm text-gray-500">
                                    <i className="ph ph-sign-out text-main" />
                                    &nbsp;Гарах
                                </Link>
                            </li>
                            <li className="nav-menu__item">
                                <img className="w-100" src="/assets/images/bg/login.png" />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <header className={`header bg-white border-bottom border-gray-100 ${scroll && "fixed-header"}`}>
                <div className="container container-lg">
                    <nav className="header-inner d-flex justify-content-between-md-evenly gap-8">
                        <div className="flex-align menu-category-wrapper">
                            <div className="category on-hover-item">
                                <Link href='' to="/category"
                                    className="category__button flex-align gap-8 fw-medium bg-main-600 p-16 border-end border-start border-gray-100 text-white">
                                    <span className="icon text-2xl d-xs-flex d-none">
                                        <i className="ph ph-dots-nine" />
                                    </span>
                                    <span className="d-sm-flex d-none">Ангилал </span>
                                </Link>
                            </div>

                            <div className="header-menu d-lg-block d-none">
                                <ul className="nav-menu flex-align ">
                                    <li className=" nav-menu__item">
                                        <div className="logo">
                                            <Link to="/" className="link">
                                                <img src="/assets/images/logo/logo.png" />
                                            </Link>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="header-right flex-align">
                            <ul className="nav-menu flex-align ">
                                <li className="nav-menu__item">
                                    <div className="flex-align flex-wrap gap-12">
                                        <Link to="/cart" className="flex-align gap-4 item-hover">
                                            <span className="text-2xl text-gray-700 d-flex position-relative me-6 mt-6 item-hover__text">
                                                <i className="ph ph-shopping-cart-simple" />
                                                <span className="w-16 h-16 flex-center rounded-circle bg-main-600 text-white text-xs position-absolute top-n6 end-n4">
                                                    { cartCount ? cartCount : cartItems}
                                                </span>
                                            </span>
                                            <span className="text-sm text-gray-500 item-hover__text d-none d-lg-flex">
                                                Сагс
                                            </span>
                                        </Link>
                                    </div>
                                </li>
                                <li className="on-hover-item nav-menu__item has-submenu">
                                    <div className="flex-align flex-wrap gap-12">
                                        <Link to="/account" className="selected-text text-sm py-8 flex-align gap-6">
                                            <span className="text-2xl text-gray-700 d-flex position-relative me-6 mt-6 item-hover__text">
                                                <i className="ph ph-user-circle" />
                                            </span>
                                            <span className="text-sm text-gray-500 item-hover__text d-none d-lg-flex">{localStorage.userName}</span>
                                        </Link>
                                        <ul className="selectable-text-list on-hover-dropdown common-dropdown common-dropdown--md max-h-200 scroll-sm px-0 py-8">
                                            <li>
                                                <Link to="/account" className="hover-bg-gray-100 text-gray-500 text-sm py-10 px-16 flex-align gap-8 rounded-0">
                                                    <i className="pi pi-id-card" style={{color: '#0771a6'}} />
                                                    Хувийн мэдээлэл
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/invoice" className="hover-bg-gray-100 text-gray-500 text-sm py-10 px-16 flex-align gap-8 rounded-0">
                                                    <i className="pi pi-book" style={{color: '#0771a6'}} />
                                                    Миний нэхэмжлэлүүд
                                                </Link>
                                            </li>
                                            <li>
                                                <Link onClick={() => setVisible(true)} className="hover-bg-gray-100 text-gray-500 text-sm py-10 px-16 flex-align gap-8 rounded-0">
                                                    <i className="ph ph-sign-out" style={{color: '#0771a6'}} />
                                                    Гарах
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="nav-menu__item">
                                    <Link to="/tel:97677038585"
                                        className="bg-main-600 text-white p-12 h-100 hover-bg-main-800 flex-align gap-8 text-md d-lg-flex d-none">
                                        <div className="d-flex text-20">
                                            <i className="ph ph-phone-call" />
                                        </div>
                                        +976 7703-8585
                                    </Link>
                                </li>
                            </ul>                       
                            <button onClick={handleMenuToggle} type="button"
                                className="toggle-mobileMenu d-lg-none ms-3n text-main text-4xl d-flex">
                                {" "}
                                <i className="ph ph-list" />{" "}
                            </button>
                        </div>
                    </nav>
                </div>
            </header>
            <header className={`mob-header bg-white border-bottom border-gray-100 ${scroll && "fixed-header"}`}>
                <div className="container container-lg">
                    <nav className="header-inner text-end">
                        <div className="header-right">      
                            <button onClick={handleMenuToggle} type="button"
                                className="toggle-mobileMenu d-lg-none ms-3n text-main text-4xl text-right">
                                {" "}
                                <i className="ph ph-list" />{" "}
                            </button>
                        </div>
                    </nav>
                </div>
            </header>
            <Dialog open={visible} onClose={() => setVisible(false)}>
                <DialogTitle style={{color: '#0771a6', fontWeight: 'bold'}} className="text-md">Анхаар!</DialogTitle>
                    <DialogContent>
                    <DialogContentText className='text-sm'><span className='pi pi-info-circle' style={{color: '#0771a6'}}></span>{" "}Та үнэхээр системээс гарахыг хүсч байна уу?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className='btn-main text-white text-xs' onClick={cancel}><span className='pi pi-times'></span>&nbsp;Үгүй</Button>
                    <Button className='btn-danger text-xs' style={{color: '#fff', background: '#ff0000'}} onClick={confirm}><span className='pi pi-check'></span>&nbsp;Тийм</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Header