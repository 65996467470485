import React, {useState, useEffect } from "react";
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { GetRequest } from "../helper/ApiConfig";
import toast, { Toaster } from 'react-hot-toast';
import PuffLoader from "react-spinners/PuffLoader";

const Feature = () => {
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState();
    const [totalElements, setTotalElements] = useState(0);

    function SampleNextArrow(props) {
        const { className, onClick } = props;
        return (
            <button type="button" onClick={onClick}
                className={` ${className} slick-next slick-arrow flex-center rounded-circle bg-white text-xl text-main-600 hover-bg-main-600 hover-text-white transition-1`}>
                <i className="ph ph-caret-right" />
            </button>
        );
    }

    function SamplePrevArrow(props) {
        const { className, onClick } = props;
        return (
            <button type="button" onClick={onClick}
                className={`${className} slick-prev slick-arrow flex-center rounded-circle bg-white text-xl text-main-600 hover-bg-main-600 hover-text-white transition-1`}>
                <i className="ph ph-caret-left" />
            </button>
        );
    }

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 10,
        autoplay: true,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1699,
                settings: {
                    slidesToShow: 10,
                },
            },
            {
                breakpoint: 1599,
                settings: {
                    slidesToShow: 10,
                },
            },
            {
                breakpoint: 1499,
                settings: {
                    slidesToShow: 9,
                },
            },
            {
                breakpoint: 1399,
                settings: {
                    slidesToShow: 8,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 6,
                },
            },
            {
                breakpoint: 840,
                settings: {
                    slidesToShow: 6,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 5,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 424,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 359,
                settings: {
                    slidesToShow: 2,
                },
            },

        ],
    };

    useEffect(() => {
        let isMounted = true;
        const fetchCategories = async () => {
            try {
                setLoading(true);
                const response = await GetRequest('category/all');
                if (isMounted) {
                    setTotalElements(response.totalElements);
                    setCategories(response.category_info.filter(i => i.parent_id == 0).sort((a, b) => a.disp_order - b.disp_order));
                }
            } catch (error) {
                if (isMounted) 
                    toast.error('Ангиллын мэдээллийг татах явцад алдаа гарлаа!' + error);
            } finally {
                setLoading(false);
            }
        };

        fetchCategories();

        return () => {
          isMounted = false;
        };
    }, []);

    return (
        <div className="feature" id="featureSection">
            <Toaster position="top-right" />
            {
                loading ? 
                (
                    <div className="container container-lg flex-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <PuffLoader color='#0771a6' loading={loading} size={50} aria-label="Уншиж байна..." data-testid="loader" />
                    </div>    
                ) 
                : 
                (
                    <div className="container container-lg">
                        <div className="position-relative arrow-center">
                            <div className="flex-align">
                                <button type="button" id="feature-item-wrapper-prev"
                                    className="slick-prev slick-arrow flex-center rounded-circle bg-white text-xl hover-bg-main-600 hover-text-white transition-1">
                                    <i className="ph ph-caret-left" />
                                </button>
                                <button type="button" id="feature-item-wrapper-next"
                                    className="slick-next slick-arrow flex-center rounded-circle bg-white text-xl hover-bg-main-600 hover-text-white transition-1">
                                    <i className="ph ph-caret-right" />
                                </button>
                            </div>
                            <div className="feature-item-wrapper">
                                <Slider {...settings}>
                                    {
                                        (categories || []).map((cat) => (
                                            <div className="feature-item text-center">
                                                <div className="feature-item__thumb rounded-circle">
                                                    <Link to={"/shop/" + cat.id + '/all/all'} className="w-90 h-90 flex-center">
                                                        <img src={'https://ingumel.mn/backend/' +  cat.cat_icon } />
                                                    </Link>
                                                </div>
                                                <div className="feature-item__content mt-16">
                                                    <h6 className="mb-5" style={{fontSize: '13px'}}>
                                                        <Link to={"/shop/" + cat.id + '/all/all'} className="text-inherit">
                                                            {cat.cat_name}
                                                        </Link>
                                                    </h6>
                                                    <span className="text-gray-400" style={{fontSize: '13px'}}>{cat.products} бараа</span>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </Slider>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>

    )
}

export default Feature