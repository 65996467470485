import React, { useEffect, useState, useMemo } from 'react';
import './vendors.css';
import './pagination.css';
import { Tree } from 'primereact/tree';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { useParams } from 'react-router-dom';

import InputBase from '@mui/material/InputBase';
import toast, { Toaster } from 'react-hot-toast';
import IconButton from '@mui/material/IconButton';
import PuffLoader from "react-spinners/PuffLoader";
import SearchIcon from '@mui/icons-material/Search';
import { PostRequest, GetRequest } from "../helper/ApiConfig";
import ResponsivePagination from 'react-responsive-pagination';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { dropEllipsis } from 'react-responsive-pagination/narrowBehaviour';

const Shop = () => {
    const { categoryId, catDate, cheap } = useParams();

    const [state, setState] = useState({
        nodes: [],
        grid: false,
        perPage: 30,
        products: [],
        active: false,
        exchRate: 0,
        loading: false,
        catLoading: false,
        dateNodes: [],
        cheapNodes: [],
        currentPage: 1,
        searchValue: "",
        selectedKey: 0,
        totalElements: 0,
        cheapProducts: [],
        pagedProducts: [],
        selectedDateKey: 'all',
        selectedCheapKey: '',
        isCheapSelected: false
    });

    const { nodes, grid, perPage, products, active, exchRate, loading, dateNodes, cheapNodes, currentPage, searchValue,
        selectedKey, totalElements, catLoading, cheapProducts, selectedDateKey, pagedProducts, selectedCheapKey, isCheapSelected } = state;

    const totalPages = useMemo(() => Math.ceil(totalElements / perPage), [totalElements, perPage]);

    const sidebarController = () => setState(prev => ({ ...prev, active: !prev.active }));

    const getDaysFromCurrentToStartOfMonth = (currentDate) => {
        const days = [];
        const month = currentDate.getMonth() + 1;
        const year = currentDate.getFullYear();
        const currentDay = currentDate.getDate();
    
        const formattedMonth = month < 10 ? `0${month}` : month;
    
        for (let day = currentDay; day >= 1; day--) {
            const formattedDay = day < 10 ? `0${day}` : day;
            days.push(`${year}-${formattedMonth}-${formattedDay}`);
        }
    
        return days;
    };
    
    const buildTree = (parentCategories, childCategories) => {
        return parentCategories.map((parent) => {
            const children = childCategories
                .filter(child => child.parent_id == parent.id)
                .map((child) => ({ key: child.id, id: child.id, label: child.cat_name }));
    
            return {
                key: parent.id,
                id: parent.id,
                label: parent.cat_name,
                children: children.length > 0 ? children : null
            };
        });
    };
    
    const buildDateTree = (dayList) => {
        const parentNode = {
            key: '',
            id: '',
            label: 'Шинэ бараа',
            children: (dayList || []).map(day => ({
                key: day,
                id: day,
                label: day
            }))
        };

        return [parentNode];
    };
    
    const buildCheapTree = (parentCategories, childCategories) => {
        const uniqueParents = new Map();
        const uniqueChildMap = new Map();
    
        parentCategories.forEach(parent => {
            if (!uniqueParents.has(parent.parentName)) {
                uniqueParents.set(parent.parentName, parent.parentId);
            }
        });
    
        const parentNode = {
            key: '',
            id: '',
            label: '2 юанийн бараа',
            children: Array.from(uniqueParents.entries()).map(([name, id]) => {
                const children = childCategories
                    .filter(child => child.childParent == id)
                    .reduce((acc, child) => {
                        if (!uniqueChildMap.has(child.childName)) {
                            uniqueChildMap.set(child.childName, child.childId);
                            acc.push({
                                key: child.childId,
                                id: child.childId,
                                label: child.childName
                            });
                        }
                        return acc;
                    }, []);
    
                return {
                    key: id,
                    id: id,
                    label: name,
                    children: children.length > 0 ? children : null
                };
            })
        };
    
        return [parentNode];
    };

    const handleSearch = async () => {
        const data = await PostRequest('product/category', { 
            cat_id: parseInt(selectedKey || 0),
            perPage: perPage,
            page: currentPage,
            pro_search: searchValue,
            pro_date: selectedDateKey || 'all'
        });
        if (data) {
            setState(prev => ({
                ...prev,
                products: data.product_info,
                exchRate: data.exchangeRate,
                totalElements: data.totalElements
            }));
        }
    }

    useEffect(() => {
        let isMounted = true;
        
        const initializeState = () => {
            if(cheap == 'all')
                setState(prev => ({ 
                    ...prev, 
                    selectedKey: categoryId || 0, 
                    selectedDateKey: catDate || 'all',
                    isCheapSelected: false,
                    dateNodes: buildDateTree(getDaysFromCurrentToStartOfMonth(new Date()))
                }));
            else
                setState(prev => ({ 
                    ...prev, 
                    selectedCheapKey: categoryId || 0,
                    selectedDateKey: catDate || 'all',
                    isCheapSelected: true,
                    dateNodes: buildDateTree(getDaysFromCurrentToStartOfMonth(new Date()))
                }));
        };
        
        const fetchCategories = async () => {
            try {
                setState(prev => ({ ...prev, catLoading: true }));
                const response = await GetRequest('category/all');
                
                if (isMounted && response.totalElements > 0) {
                    const parentCategories = response.category_info.filter(cat => cat.parent_id == 0).sort((a, b) => a.disp_order - b.disp_order);
                    const childCategories = response.category_info.filter(cat => cat.parent_id != 0).sort((a, b) => a.disp_order - b.disp_order);
                    setState(prev => ({ ...prev, nodes: buildTree(parentCategories, childCategories) }));
                }
            } catch (error) {
                toast.error('Ангиллын жагсаалт татах явцад алдаа гарлаа!' + error);
            } finally {
                setState(prev => ({ ...prev, catLoading: false, active: false }));
            }
        };
    
        const fetchCheapProducts = async () => {
            const data = await GetRequest('product/twoyuan');
            if (isMounted && data) {
                const parentCategories = data.product_info.filter(cat => cat.parentPar == 0);
                const childCategories = data.product_info.filter(cat => cat.childParent != 0);
                const cheapTreeData = buildCheapTree(parentCategories, childCategories);
                setState(prev => ({ ...prev, cheapNodes: cheapTreeData, cheapProducts: data.product_info, exchRate: data.exchangeRate }));
            }
        };

        fetchCategories();
        fetchCheapProducts();
        initializeState();
    
        return () => {
            isMounted = false;
        };
    }, [])

    useEffect(() => {
        let isMounted = true;

        const fetchProducts = async () => {
            setState((prev) => ({...prev, loading: true}));
            try {
                const data = await PostRequest('product/category', {
                    cat_id: parseInt(selectedKey),
                    perPage: perPage,
                    page: currentPage,
                    pro_search: searchValue,
                    pro_date: selectedDateKey
                });
                if(isMounted && data?.totalElements > 0)
                    setState(prev => ({
                        ...prev,
                        products: data.product_info,
                        exchRate: data.exchangeRate,
                        totalElements: data.totalElements,
                        active: false
                    }));
                else 
                    setState(prev => ({ ...prev, 
                        products: [],
                        totalElements: 0,
                        active: false
                    }));
            } catch (error) {
                toast.error('Барааны жагсаалт татах явад алдаа гарлл, дахин оролдоно уу.')
            } finally {
                setState((prev) => ({...prev, loading: false}));
            }
        };
    
        fetchProducts();

        return () => {
            isMounted = false;
        };
    }, [selectedKey, selectedDateKey, perPage, currentPage, isCheapSelected]);

    const findNodeByKey = (nodes, key) => {
        for (const node of nodes) {
            if (node.key == key) return node;
            if (node.children) {
                const found = findNodeByKey(node.children, key);
                if (found) return found;
            }
        }
        return null;
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentPage, selectedCheapKey, selectedDateKey, selectedKey]);
    
    // Helper function to get all subcategories recursively
    const getAllSubcategories = (node) => {
        let subcategories = [];
        if (node.children) {
            for (const child of node.children) {
                subcategories.push(child.key); // Add child key
                if (child.children)
                    subcategories = subcategories.concat(getAllSubcategories(child)); // Add subchild keys
            }
        }
        return subcategories;
    };

    useEffect(() => {
        const startIndex = (currentPage - 1) * perPage;
        const endIndex = startIndex + perPage;
    
        let filteredProducts = [];
    
        if (selectedCheapKey == '') {
            // If no node is selected, show all cheap products
            filteredProducts = cheapProducts;
        } else {
            // Check if the selected key is a parent, child, or subchild node
            const selectedNode = findNodeByKey(cheapNodes, selectedCheapKey);
    
            if (selectedNode) {
                if (selectedNode.children) {
                    // If the selected node is a parent or child, get all subcategories recursively
                    const allSubcategories = getAllSubcategories(selectedNode);
                    filteredProducts = cheapProducts.filter(pro => allSubcategories.includes(pro.pro_category));
                } else {
                    // If the selected node is a subchild, filter products by its category
                    filteredProducts = cheapProducts.filter(pro => pro.pro_category == selectedCheapKey);
                }
            }
        }
    
        // Update the state with paginated products
        setState(prev => ({
            ...prev,
            pagedProducts: filteredProducts.slice(startIndex, endIndex),
            totalElements: Number(filteredProducts.length),
            active: false
        }));
    }, [selectedCheapKey, perPage, currentPage, cheapProducts, cheapNodes]);

    return (
        <section className="vendor-two mt-30">
            <Toaster position="top-right" />
            <div className={`side-overlay ${active && "show"}`}></div>
            <div className="container container-lg">
                <div className="row">
                    <div className="col-xl-3 col-lg-4">
                        <div className={`shop-sidebar ${active && "active"}`}>
                            <button onClick={sidebarController} type="button"
                                className="shop-sidebar__close d-lg-none d-flex w-32 h-32 flex-center border border-gray-100 rounded-circle hover-bg-main-600 position-absolute inset-inline-end-0 me-10 mt-8 hover-text-white hover-border-main-600">
                                <i className="ph ph-x" />
                            </button>
                            <div className="shop-sidebar__box border border-gray-100 rounded-8 p-32 mb-32">
                                <h6 className="text-lg border-bottom border-gray-100 pb-24 mb-24">
                                    Барааны ангилал
                                </h6>
                                {
                                    catLoading ? 
                                    ( 
                                        <div className="flex-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <PuffLoader color='#0771a6' loading={loading} size={50} aria-label="Уншиж байна..." data-testid="loader" />
                                        </div>
                                    )
                                    :
                                    (
                                        <>
                                            <Link className="custom-tree" href={''} onClick={() => window.location.href = '/shop/0/all/all'} style={{marginLeft: '30px', color: '#4b5563'}}>Бүх барааг харах</Link>
                                            <Tree value={dateNodes} className="custom-tree" selectionMode="single" onExpand={e => setState(prev => ({ ...prev, isCheapSelected: false }))}
                                                selectionKeys={selectedDateKey} onSelectionChange={e => setState(prev => ({ ...prev, selectedDateKey: e.value, currentPage: 1 }))} />
                                            <Tree value={cheapNodes} className="custom-tree" selectionMode="single" onExpand={e => setState(prev => ({ ...prev, isCheapSelected: true }))}
                                                selectionKeys={selectedCheapKey} onSelectionChange={e => setState(prev => ({ ...prev, selectedCheapKey: e.value, isCheapSelected: true, currentPage: 1 }))} />
                                            <Tree value={nodes} className="custom-tree" selectionMode="single" onExpand={e => setState(prev => ({ ...prev, isCheapSelected: false, currentPage: 1 }))}
                                                selectionKeys={selectedKey} onSelectionChange={e => setState(prev => ({ ...prev, selectedKey: e.value }))} />
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    {
                        loading ? 
                        ( 
                            <div className="col-xl-9 col-lg-8 flex-center" style={{ height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <PuffLoader color='#0771a6' loading={loading} size={50} aria-label="Уншиж байна..." data-testid="loader" />
                            </div>
                        )
                        :
                        (
                            <div className="col-xl-9 col-lg-8">
                                <div className="flex-between gap-16 flex-wrap mb-20">
                                    {
                                        totalElements > perPage ? 
                                        (
                                            <span className="text-gray-900">Нийт <strong>{totalElements}</strong> бараанаас <strong>1-{perPage}</strong> хүртэлхийг харуулж байна
                                            </span>
                                        ) :
                                        (
                                            <span className="text-gray-900">Нийт <strong>{totalElements}</strong> бараанаас <strong>1-{totalElements}</strong> хүртэлхийг харуулж байна
                                            </span>
                                        )
                                    }
                                    <div className="position-relative flex-align gap-16 flex-wrap">
                                        <div className="position-relative text-gray-600 flex-align gap-4 text-14">
                                            <label htmlFor="sorting" className="text-inherit flex-shrink-0 text-gray-600">
                                                Харуулах барааны тоо:{" "}
                                            </label>
                                            <select className="form-control common-input px-14 py-14 text-inherit rounded-6 w-auto"
                                                value={perPage} onChange={(e) => setState(prev => ({ ...prev, perPage: e.target.value }))} id="sorting">
                                                <option value={10}>10</option>
                                                <option value={30}>30</option>
                                                <option value={50}>50</option>
                                                <option value={100}>100</option>
                                            </select>
                                        </div>
                                        <div className='d-flex align-items-center gap-8 d-sm-flex d-none hide-mob'>
                                            <Paper component="form" className='d-flex align-items-center w-100 common-input px-10 py-1'>
                                                <InputBase placeholder="Барааны нэрээр хайх..."
                                                    value={searchValue} onChange={(e) => setState(prev => ({ ...prev, searchValue: e.target.value }))}/>
                                                <IconButton type="button" onClick={() => handleSearch()}>
                                                    <SearchIcon className='text-main'/>
                                                </IconButton>
                                            </Paper>
                                        </div>
                                        <button onClick={sidebarController} type='button'
                                            className='w-48 h-48 d-lg-none d-flex flex-center border border-gray-100 rounded-6 text-2xl sidebar-btn'>
                                            <i className='ph-bold ph-funnel' />
                                        </button>
                                    </div>
                                    <div className="position-relative flex-align gap-16 flex-wrap show-mob w-100 mb-2">
                                        <Paper component="form" className='d-flex align-items-center w-100 common-input px-10 py-1'>
                                            <InputBase style={{width: '420px'}} placeholder="Барааны нэрээр хайх..."
                                                value={searchValue} onChange={(e) => setState(prev => ({ ...prev, searchValue: e.target.value }))}/>
                                            <IconButton type="button" onClick={() => handleSearch()}>
                                                <SearchIcon className='text-main'/>
                                            </IconButton>
                                        </Paper>
                                    </div>
                                </div>
                                <div className={`list-grid-wrapper vendors-two-item-wrapper grid-cols-3 ${grid && "list-view"}`}>
                                {
                                    isCheapSelected && pagedProducts?.length > 0 && 
                                        (pagedProducts || []).map((pro) => ( 
                                            <div className="vendors-two-item rounded-12 overflow-hidden bg-color-three border border-neutral-50 hover-border-main-600 transition-2">
                                                <div className='vendors-two-item__top bg-overlay style-two position-relative'>
                                                    <div className='vendors-two-item__thumbs' style={{height: '13rem'}}>
                                                        <LazyLoadImage className="cover-img" src={'https://ingumel.mn/backend/' + pro.pro_image} />
                                                    </div>
                                                    <div className='position-absolute top-0 inset-inline-start-0 w-100 h-100 p-24 z-1 d-flex flex-column justify-content-between'>
                                                        <div className='d-flex align-items-center justify-content-between'></div>
                                                        <div className='mt-16'>
                                                            <h6 className='text-white fw-semibold mb-12'>
                                                                <Link to={"/product/" + pro.pro_code} className="text-line-2 text-md hover-text-white">
                                                                    {pro.pro_name}
                                                                </Link>
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="vendors-two-item__content p-24 flex-grow-1">
                                                    <div className="d-flex flex-column gap-14">
                                                        <div className="flex-align gap-8">
                                                            <span className="flex-center text-main-600 text-xl flex-shrink-0">
                                                                <i className="pi pi-th-large" />
                                                            </span>
                                                            <span className="text-gray-900" style={{fontSize: '13px'}}>
                                                                {pro.childName}
                                                            </span>
                                                        </div>
                                                        <div className="flex-align gap-8">
                                                            <span className="flex-center text-main-600 text-xl flex-shrink-0">
                                                                <i className="pi pi-hashtag" />
                                                            </span>
                                                            <span className="text-gray-900" style={{fontSize: '13px'}}>
                                                                Код: {pro.pro_code}
                                                            </span>
                                                        </div>
                                                        <div className="flex-align gap-8">
                                                            <span className="flex-center text-main-600 text-xl flex-shrink-0">
                                                                <i className="pi pi-money-bill" />
                                                            </span>
                                                            <span className="text-gray-900" style={{fontSize: '13px'}}>
                                                                Үнэ: ¥{pro.pro_price}
                                                                <span>&nbsp;/&nbsp;{parseInt(pro.pro_price * Number(exchRate))}₮</span>{" "}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                }
                                {
                                    !isCheapSelected && products?.length > 0 && 
                                        (products || []).map((pro) => ( 
                                            <div className="vendors-two-item rounded-12 overflow-hidden bg-color-three border border-neutral-50 hover-border-main-600 transition-2">
                                                <div className='vendors-two-item__top bg-overlay style-two position-relative'>
                                                    <div className='vendors-two-item__thumbs' style={{height: '13rem'}}>
                                                        <LazyLoadImage className="cover-img" src={'https://ingumel.mn/backend/' + pro.pro_image} />
                                                    </div>
                                                    <div className='position-absolute top-0 inset-inline-start-0 w-100 h-100 p-24 z-1 d-flex flex-column justify-content-between'>
                                                        <div className='d-flex align-items-center justify-content-between'></div>
                                                        <div className='mt-16'>
                                                            <h6 className='text-white fw-semibold mb-12'>
                                                                <Link to={"/product/" + pro.pro_code} className="text-line-2 text-md hover-text-white">
                                                                    {pro.pro_name}
                                                                </Link>
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="vendors-two-item__content p-24 flex-grow-1">
                                                    <div className="d-flex flex-column gap-14">
                                                        <div className="flex-align gap-8">
                                                            <span className="flex-center text-main-600 text-xl flex-shrink-0">
                                                                <i className="pi pi-th-large" />
                                                            </span>
                                                            <span className="text-gray-900" style={{fontSize: '13px'}}>
                                                                {pro.cat_name}
                                                            </span>
                                                        </div>
                                                        <div className="flex-align gap-8">
                                                            <span className="flex-center text-main-600 text-xl flex-shrink-0">
                                                                <i className="pi pi-hashtag" />
                                                            </span>
                                                            <span className="text-gray-900" style={{fontSize: '13px'}}>
                                                                Код: {pro.pro_code}
                                                            </span>
                                                        </div>
                                                        <div className="flex-align gap-8">
                                                            <span className="flex-center text-main-600 text-xl flex-shrink-0">
                                                                <i className="pi pi-money-bill" />
                                                            </span>
                                                            <span className="text-gray-900" style={{fontSize: '13px'}}>
                                                                Үнэ: ¥{pro.pro_price}
                                                                <span>&nbsp;/&nbsp;{parseInt(pro.pro_price * parseInt(exchRate))}₮</span>{" "}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }{
                                        !isCheapSelected && products?.length == 0 &&
                                        (<span className="text-gray-900">
                                            <img src='https://ingumel.mn/backend/assets/images/category/noproduct.png' style={{marginLeft: '10%'}}/> <br/>Бараа байхгүй байна
                                        </span>)
                                }
                                </div>
                                <ResponsivePagination className="pagination flex-center flex-wrap mt-20"
                                    current={currentPage} total={totalPages} onPageChange={(page) => setState(prev => ({ ...prev, currentPage: page }))} narrowBehaviour={dropEllipsis} />
                            </div>
                        )
                    }
                </div>
            </div>
        </section>
    )
}

export default Shop