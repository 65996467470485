import React from "react";
import Login from "../components/Login";
import Preloader from "../helper/Preloader";
import ScrollToTop from "react-scroll-to-top";
import HeaderLogin from "../components/HeaderLogin";
import BottomFooter from "../components/BottomFooter";

const LoginPage = () => {

  return (
    <>
      <Preloader />
      
      <ScrollToTop smooth color="#0771a6" />

      <HeaderLogin />

      <Login />

      <BottomFooter />
    </>
  );
};

export default LoginPage;