import React, { useEffect, useState, useCallback } from 'react';
import './category-list.css';
import { Link } from 'react-router-dom';
import { GetRequest } from "../helper/ApiConfig";
import toast, { Toaster } from 'react-hot-toast';
import PuffLoader from "react-spinners/PuffLoader";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const CategorySection = () => {
    const [ loading, setLoading ] = useState(true);
    const [ childCats, setChildCats ] = useState([]);
    const [ parentCats, setParentCats ] = useState([]);
    const [ daysInMonth, setDaysInMonth ] = useState([]);
    const [ selectedParent, setSelectedParent ] = useState(0);
    const [ childCategories, setChildCategories ] = useState([]);
    const [ selectedCategory, setSelectedCategory ] = useState(0);
    const [ parentCategories, setParentCategories ] = useState([]);
    const [navigationHistory, setNavigationHistory] = useState([]);
    const [ selectedCheapParent, setSelectedCheapParent ] = useState(0);

    const handleGoBack = useCallback(() => {
        console.log('handleGoBack called');
        if (navigationHistory.length > 0) {
          console.log('navigationHistory:', navigationHistory);
          setNavigationHistory(prevHistory => prevHistory.slice(0, -1));
          const previousNode = navigationHistory[navigationHistory.length - 1];
          console.log('previousNode:', previousNode);
          setSelectedParent(previousNode.parentId);
          setSelectedCategory(previousNode.categoryId);
        } else {
          console.log('navigationHistory is empty');
        }
      }, [navigationHistory, setSelectedParent, setSelectedCategory]);

    const handleCategoryClick = (parentId, categoryId, catId) => {
        setNavigationHistory(prev => [...prev, { parentId, categoryId }]);
        setSelectedParent(parentId);
        setSelectedCategory(categoryId);
        catId && handleCheapChange(catId);
    };

    const getDaysFromCurrentToStartOfMonth = (currentDate) => {
        const days = [];
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1;
        const currentDay = currentDate.getDate();
        const formattedMonth = month < 10 ? `0${month}` : month;
    
        for (let day = currentDay; day >= 1; day--) {
            const formattedDay = day < 10 ? `0${day}` : day;
            days.push(`${year}-${formattedMonth}-${formattedDay}`);
        }
    
        return days;
    };

    const fetchCheapProducts = async (id) => {
        try {
            const uniqueParents = new Map();
            const uniqueChilds = new Map();
    
            const response = await GetRequest('product/twoyuan');
    
            if (response) {
                if(id == 0) {
                    const parentCats = response.product_info.filter(cat => cat.parentPar == 0);
    
                    parentCats.forEach(parent => {
                        if (!uniqueParents.has(parent.parentName)) {
                            uniqueParents.set(parent.parentName, { id: parent.parentId, image: parent.pro_image });
                        }
                    });
                    setParentCats(Array.from(uniqueParents.entries()));
                } else {
                    const childCats = response.product_info.filter(cat => cat.childParent == id);
    
                    childCats.forEach(child => {
                        if (!uniqueChilds.has(child.childName)) {
                            uniqueChilds.set(child.childName, { id: child.childId, image: child.pro_image });
                        }
                    });
                    setChildCats(Array.from(uniqueChilds.entries()));
                }
            }
        } catch (error) {
            toast.error('2 юаны барааны ангилал үүсгэх явцад алдаа гарлаа!' + error);
        }
    }

    const handleCheapChange = (id) => {
        setSelectedCheapParent(id);
        fetchCheapProducts(id);
    }

    useEffect(() => {
        let isMountedCategory = true;

        const fetchCategories = async () => {
            try {
                setLoading(true);
                const response = await GetRequest('category/all');
                if (isMountedCategory) {
                    const parentCategories = response.category_info.filter(cat => cat.parent_id == 0).sort((a, b) => a.disp_order - b.disp_order);
                    setParentCategories(parentCategories);

                    const childCategories = response.category_info.filter(cat => cat.parent_id != 0).sort((a, b) => a.disp_order - b.disp_order);
                    setChildCategories(childCategories);
                    
                }
                setLoading(false);
            } catch (error) {
                if (isMountedCategory)
                    toast.error('Ангиллын мэдээллийг татах явцад алдаа гарлаа!' + error);
            }
        };

        fetchCategories();
        
        return () => {
            isMountedCategory = false;
        };
    }, []);

    useEffect(() => {
        if (selectedCategory == 99998) {
            const currentDate = new Date();
            const days = getDaysFromCurrentToStartOfMonth(currentDate);
            setDaysInMonth(days);
        }

        if (selectedCategory == 99999) 
            fetchCheapProducts(0);
        
    }, [selectedCategory]);

    return (
        <section className="shipping mt-20">
            <Toaster position="top-right" />
            {
                loading ? 
                (
                    <div className="list-container container-lg flex-center" style={{ height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <PuffLoader color='#0771a6' loading={loading} size={50} aria-label="Уншиж байна..." data-testid="loader" />
                    </div>    
                ) 
                : 
                (
                    <div className="list-container container-lg">
                        <aside className="sidebar">
                            <div className="shop-sidebar__box border border-gray-100 rounded-8 p-20">
                                <h6 className="text-md border-bottom border-gray-100 pb-20">
                                    Үндсэн ангилал
                                </h6>
                                <ul className="max-h-540 overflow-y-auto scroll-sm scroll-primary">
                                    <li className="mb-10 text-xs">
                                        <Link to='#' onClick={() => handleCategoryClick(0, 99998, 0)}>
                                            <span className="text-main-600 text-xl">
                                                <i className="pi pi-th-large" />
                                            </span>
                                            <span className={`text-sm text-gray-900 ${selectedCategory == 99998 && 'fw-bold'}`}>
                                                &nbsp;Шинэ бараа
                                            </span>
                                        </Link>
                                    </li>
                                    <li className="mb-10 text-xs">
                                        <Link to='#' onClick={() => { handleCategoryClick(0, 99999, 0)}}>
                                            <span className="text-main-600 text-xl">
                                                <i className="pi pi-th-large" />
                                            </span>
                                            <span className={`text-sm text-gray-900 ${selectedCategory == 99999 && 'fw-bold'}`}>
                                                &nbsp;2 юанийн бараа
                                            </span>
                                        </Link>
                                    </li>
                                    {
                                        (parentCategories || []).map((cat) => (
                                            <li className="mb-10 text-xs">
                                                {
                                                    cat.id == 21 ? 
                                                    (<Link to={`/shop/${cat.id}/all/all`}>
                                                        <span className="text-main-600 text-xl">
                                                            <i className="pi pi-th-large" />
                                                        </span>
                                                        <span className={`text-sm text-gray-900 ${selectedCategory == cat.id && 'fw-bold'}`}>
                                                            &nbsp;{cat.cat_name}
                                                        </span>
                                                    </Link>)
                                                    :
                                                    (<Link to='#' onClick={() => { handleCategoryClick(cat.id, cat.id, null)}}>
                                                        <span className="text-main-600 text-xl">
                                                            <i className="pi pi-th-large" />
                                                        </span>
                                                        <span className={`text-sm text-gray-900 ${selectedCategory == cat.id && 'fw-bold'}`}>
                                                            &nbsp;{cat.cat_name}
                                                        </span>
                                                    </Link>)
                                                }
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </aside>

                        <main className="main-content">
                            <div className='row border-bottom border-gray-100 pb-20'>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 text-left">
                                    <h6 className="text-md">
                                        Дэд ангилал
                                    </h6>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 text-end">
                                    {selectedCategory > 0 && ( <Link onClick={() => handleGoBack()} className='goback text-md text-primary'>&#171;&nbsp;Буцах</Link> )}
                                </div>
                            </div>
                            {
                                selectedCategory == 99998 &&
                                (
                                    <div className='grid'>
                                        {
                                            daysInMonth.map(day => (
                                                <div className="feature-item text-center">
                                                    <div className="feature-item__thumb">
                                                        <Link to={`/shop/0/${day}/all`} className="w-90 h-90 flex-center">
                                                            <LazyLoadImage className="cover-img" src='https://ingumel.mn/backend/assets/images/category/calendar.png' />
                                                        </Link>
                                                    </div>
                                                    <div className="feature-item__content mt-8">
                                                        <h6 className="text-sm mb-0">
                                                            <Link to={`/shop/0/${day}/all`} className="text-inherit">
                                                                {day}
                                                            </Link>
                                                        </h6>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                )
                            }
                            {
                                selectedCategory == 99999 && selectedCheapParent == 0 ?
                                (
                                    <div className='grid'>
                                        {
                                            (parentCats || []).map(([name, { id, image }]) => (
                                                <div className="feature-item text-center">
                                                    <div className="feature-item__thumb">
                                                        <Link href={''} onClick={() => handleCategoryClick(id, 99999, id)} className="w-90 h-90 flex-center">
                                                            <LazyLoadImage className="cover-img" src={'https://ingumel.mn/backend/' + image} />
                                                        </Link>
                                                    </div>
                                                    <div className="feature-item__content mt-8">
                                                        <h6 className="text-sm mb-0">
                                                            <Link href={''} onClick={() => { handleCategoryClick(id, 99999, id)}} className="text-inherit">
                                                                {name}
                                                            </Link>
                                                        </h6>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                ) 
                                :
                                selectedCategory == 99999 && selectedCheapParent != 0 ?
                                (
                                    <div className='grid'>
                                        {
                                            (childCats || []).map(([name, { id, image }]) => (
                                                <div className="feature-item text-center">
                                                    <div className="feature-item__thumb">
                                                        <Link to={`/shop/${id}/all/cheap`} className="w-90 h-90 flex-center">
                                                            <LazyLoadImage className="cover-img" src={'https://ingumel.mn/backend/' + image} />
                                                        </Link>
                                                    </div>
                                                    <div className="feature-item__content mt-8">
                                                        <h6 className="text-sm mb-0">
                                                            <Link to={`/shop/${id}/all/cheap`} className="text-inherit">
                                                                {name}
                                                            </Link>
                                                        </h6>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                )
                                :
                                (<></>)
                            }
                            <div className="grid">
                            {
                                (childCategories.filter((i) => i.parent_id == selectedCategory)).length > 0 &&
                                (childCategories.filter((i) => i.parent_id == selectedCategory).map((cat) => (
                                    <div className="feature-item text-center">
                                        <div className="feature-item__thumb">
                                            <Link to={"/shop/" + cat.id + '/all/all'} className={cat.first_product_image == 'assets/images/category/noproduct.png' ? 'w-90 h-90 flex-center' : 'w-100 h-100 flex-center'}>
                                                <LazyLoadImage src={'https://ingumel.mn/backend/' + cat.first_product_image} /> 
                                            </Link>
                                        </div>
                                        <div className="feature-item__content mt-10">
                                            <h6 style={{fontSize: '13px'}}>
                                                <Link to={"/shop/" + cat.id + '/all/all'} className="text-inherit">
                                                    {cat.cat_name}
                                                </Link>
                                            </h6>
                                        </div>
                                    </div>
                                ))) 
                            }
                            </div>
                            {
                                (selectedCategory != 99999 && selectedCategory != 99998 && childCategories.filter((i) => i.parent_id == selectedCategory)).length == 0 && 
                                (
                                    <div className="feature-item text-center">
                                        <span className='text-gray-900 text-sm'>Дэд ангилал байхгүй байна</span>
                                    </div>
                                )
                            }
                        </main>
                    </div>
                )
            }
        </section>
    )
}

export default CategorySection