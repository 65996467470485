import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Feature from "../components/Feature";
import Preloader from "../helper/Preloader";
import Delivery from "../components/Delivery";
import Shipping from "../components/Shipping";
import ScrollToTop from "react-scroll-to-top";
import BottomNav from "../components/BottomNav";
import Recommended from "../components/Recommended";
import BottomFooter from "../components/BottomFooter";

const HomePage = () => {
  const [ cartCount ] = useState(localStorage.cartLength || 0);

  return (
    <>
      <Preloader />

      <ScrollToTop smooth color="#0771a6" />

      <Header cartCount={cartCount}/>

      <BottomNav />

      <Feature />

      <Recommended />

      <Delivery />

      <Shipping />

      <Footer />

      <BottomFooter />
    </>
  );
};

export default HomePage;