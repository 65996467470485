import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userId, setUserId] = useState('');
  const [userName, setUserName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const checkSessionValidity = () => {
    if (localStorage.loginTime) {
      const currentTime = new Date().getTime();
      const timeDifference = currentTime - parseInt(localStorage.loginTime, 10);
      const hoursDifference = timeDifference / (1000 * 60 * 60);

      if (hoursDifference > 48)
        logout();
      else 
        localStorage.setItem('loginTime', new Date().getTime().toString());
    }
  };

  useEffect(() => {
    checkSessionValidity();
  }, []);

  const login = (user_name, phone_number, user_id) => {
    setUserName(user_name);
    setPhoneNumber(phone_number);
    setUserId(user_id);
    localStorage.setItem('userName', user_name);
    localStorage.setItem('phoneNumber', phone_number);
    localStorage.setItem('userId', user_id);
    localStorage.setItem('loginTime', new Date().getTime().toString());
  };

  const logout = () => {
    setUserName('');
    setPhoneNumber('');
    setUserId('');
    localStorage.removeItem('userName');
    localStorage.removeItem('phoneNumber');
    localStorage.removeItem('regNum');
    localStorage.removeItem('userId');
    localStorage.removeItem('userSoumName');
    localStorage.removeItem('userCityName');
    localStorage.removeItem('userSoumId');
    localStorage.removeItem('userCityId');
    localStorage.removeItem('userFB');
    localStorage.removeItem('loginTime');
  };

  return (
    <AuthContext.Provider value={{ userName, phoneNumber, userId, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};