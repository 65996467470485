import React from 'react';
import './bottom-footer.css'

const BottomFooter = () => {
    const currentYear = new Date().getFullYear();
    return (
        <div className="bottom-footer" style={{backgroundColor: '#e8eff7'}}>
            <div className="container container-lg">
                <div className="bottom-footer__inner flex-between flex-wrap gap-16 py-16">
                    <p className="bottom-footer__text text-sm" style={{color: '#0771a6', fontWeight: '400'}}>
                        Энх-Ингүмэл ХХК © {currentYear} | Зохиогчийн эрх хуулиар хамгаалагдсан
                    </p>
                </div>
            </div>
        </div>
    )
}

export default BottomFooter