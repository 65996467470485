import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { PostRequest } from "../helper/ApiConfig";
import PuffLoader from "react-spinners/PuffLoader";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Recommended = () => {
    const [ products, setProducts ] = useState();
    const [ exchRate, setExchRate ] = useState(0);
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        let isMountedAll = true;
        const fetchProducts = async () => {
            try {
                setLoading(true);
                const response = await PostRequest('product/col', { col : "" });
                if (isMountedAll) {
                    setProducts(response.product_info);
                    setExchRate(response.exchangeRate);
                }
            } catch (error) {
                if (isMountedAll)
                    toast.error('Барааны жагсаалтыг татах явцад алдаа гарлаа!' + error);
            } finally {
                setLoading(false);
            }
        };

        fetchProducts();
        
        return () => {
            isMountedAll = false;
        };
    }, []);

    return (
        <section className="recommended mt-30">
            <Toaster position="top-right" />
            {
                loading ? 
                (
                    <div className="container container-lg flex-center" style={{ height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <PuffLoader color='#0771a6' loading={loading} size={50} aria-label="Уншиж байна..." data-testid="loader" />
                    </div>      
                ) : 
                (
                    <div className="container container-lg">
                        <div className="section-heading flex-between flex-wrap gap-16">
                            <h5 className="mb-0 text-xl">Барааны жагсаалт</h5>
                        </div>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-all" role="tabpanel" aria-labelledby="pills-all-tab" tabIndex={0}>
                                <div className="row g-12">
                                    {
                                        (products || []).map((pro) => (
                                            <div className="col-xxl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                                <div className="vendors-two-item rounded-12 overflow-hidden bg-color-three border border-neutral-50 hover-border-main-600 transition-2">
                                                    <div className='vendors-two-item__top bg-overlay style-two position-relative'>
                                                        <div className='vendors-two-item__thumbs' style={{height: '13rem'}}>
                                                            <LazyLoadImage className="cover-img" src={'https://ingumel.mn/backend/' + pro.pro_image} />
                                                        </div>
                                                        <div className='position-absolute top-0 inset-inline-start-0 w-100 h-100 p-24 z-1 d-flex flex-column justify-content-between'>
                                                            <div className='d-flex align-items-center justify-content-between'></div>
                                                            <div className='mt-16'>
                                                                <h6 className='text-white fw-semibold mb-12 text-center'>
                                                                    <Link to={"/product/" + pro.pro_code} className="text-line-2 text-md hover-text-white">
                                                                        {pro.pro_name}
                                                                    </Link>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="vendors-two-item__content p-24 flex-grow-1">
                                                        <div className="d-flex flex-column gap-14">
                                                            <div className="flex-align gap-8">
                                                                <span className="flex-center text-main-600 text-xl flex-shrink-0">
                                                                    <i className="pi pi-th-large" />
                                                                </span>
                                                                <span className="text-gray-900" style={{fontSize: '13px'}}>
                                                                    {pro.cat_name}
                                                                </span>
                                                            </div>
                                                            <div className="flex-align gap-8">
                                                                <span className="flex-center text-main-600 text-xl flex-shrink-0">
                                                                    <i className="pi pi-hashtag" />
                                                                </span>
                                                                <span className="text-gray-900" style={{fontSize: '13px'}}>
                                                                    Код: {pro.pro_code}
                                                                </span>
                                                            </div>
                                                            <div className="flex-align gap-8">
                                                                <span className="flex-center text-main-600 text-xl flex-shrink-0">
                                                                    <i className="pi pi-money-bill" />
                                                                </span>
                                                                <span className="text-gray-900" style={{fontSize: '13px'}}>
                                                                    Үнэ: ¥{pro.pro_price}
                                                                    <span>&nbsp;/&nbsp;{parseInt(pro.pro_price * parseInt(exchRate))}₮</span>{" "}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                    <div class="section-heading">
                                        <div class="flex-between flex-wrap gap-8">
                                            <h5 class="mb-0"></h5>
                                            <Link to="/shop/0/all/all" className="mt-16 btn btn-main text-sm fw-medium d-inline-flex align-items-center rounded-pill gap-8">
                                                Бүх барааг үзэх
                                                <span className="icon text-md d-flex">
                                                    <i className="ph ph-arrow-right" />
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </section>
    )
}

export default Recommended