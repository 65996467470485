import { useEffect, useState } from "react";
import PropagateLoader from "react-spinners/PropagateLoader";

const Preloader = () => {
  let [active, setActive] = useState(true);
  
  useEffect(() => {
    const timer = setTimeout(() => setActive(false), 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {
        active ? 
        (
          <div className="preloader">
            <PropagateLoader color='#0771a6' size={15} aria-label="Уншиж байна..." data-testid="loader" />
          </div>
        ) 
        : 
        (<div></div>)
      }
    </>
  );
};

export default Preloader;