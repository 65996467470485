import React, { useEffect, useState, useMemo, useCallback } from 'react';
import 'jspdf-autotable'; 
import jsPDF from 'jspdf';
import { format } from 'date-fns';
import localForage from 'localforage';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import toast, { Toaster } from 'react-hot-toast';
import { PostRequest } from "../helper/ApiConfig";
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import './arial-mon.js';
import './arial-mon-bold.js';
import stampImg from './stamp.png';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/lara-light-blue/theme.css';

const CartSection = ({removeFromCart}) => {
    const [cart, setCart] = useState([]);
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const excRate = parseFloat(localStorage.excRate);
    const [visibleInv, setVisibleInv] = useState(false);
    const [selectedItem, setSelectedItem] = useState(0);
    const [invoiceNumber, setInvoiceNumber] = useState(0);
  
    const totalPriceYn = useMemo(() => cart.reduce((acc, item) => acc + Number(item.pro_price) * Number(item.quantity), 0), [cart]);
    const totalPriceTk = useMemo(() => parseInt(totalPriceYn * excRate), [totalPriceYn, excRate]);
  
    const removeItemFromCart = useCallback(
        async (itemId) => {
          try {
            const updatedCart = cart.filter((cartItem) => cartItem.id !== itemId);
            await localForage.setItem('cart', updatedCart); 
            localStorage.setItem('cartLength', updatedCart.length);
            setCart(updatedCart);
            removeFromCart();
            toast.success('Бараа сагснаас хасагдлаа!');
          } catch (error) {
            console.error('Error removing item from cart:', error);
            toast.error('Бараа хасахад алдаа гарлаа!');
          }
        },
        [cart, removeFromCart]
      );
  
    const confirm = useCallback(() => {
      removeItemFromCart(selectedItem);
      setVisible(false);
    }, [removeItemFromCart, selectedItem]);
  
    const showDialog = useCallback((proId) => {
      setVisible(true);
      setSelectedItem(proId);
    }, []);
  
    const incrementQuantity = useCallback(
        async (id, orderCnt) => {
            try {
                const updatedCart = cart.map((item) => item.id == id ? { ...item, quantity: Number(item.quantity) + Number(orderCnt) } : item
            );
                await localForage.setItem('cart', updatedCart);
                setCart(updatedCart);
            } catch (error) {
                toast.error('Тоо ширхэг нэмэхэд алдаа гарлаа!');
            }
        },
        [cart]
    );
  
    const decrementQuantity = useCallback(
        async (id, orderCnt) => {
            try {
                const updatedCart = cart.map((item) => item.id == id ? { ...item, quantity: Math.max(0, item.quantity - orderCnt ) } : item
            );
                await localForage.setItem('cart', updatedCart);
                setCart(updatedCart);
            } catch (error) {
                toast.error('Тоо ширхэг бууруулахад алдаа гарлаа!');
            }
        },
        [cart]
    );
    
    const cancel = () => setVisible(false);

    const generateInvoicePDF = () => {
        try { 
            setLoading(true);
            const doc = new jsPDF();

            doc.setFont('MAK Arial Bold');
            doc.setFontSize(14);
            const title = "НЭХЭМЖЛЭЛ";
            const padding = 15;
            const titleWidth = doc.getTextWidth(title);
            const center = (doc.internal.pageSize.width / 2) - (titleWidth / 2);
            doc.text(title, center, padding);

            doc.setFont('MAK Arial');
            doc.setFontSize(11);
            doc.text('Нэхэмжлэгч: “Энх-Ингүмэл Эс И Эй Эс” ХХК', 15, 25);
            doc.text('Регистрийн №: 7017537', 15, 31);
            doc.text('Хаяг: СХД, 6-р хороо, Тоосго 15а гудамж, 12 тоот', 15, 37);
            doc.text('Утас:7703-8585, 9811-7949', 15, 43);
            doc.text('Банк: Хаан банк', 15, 49);
            doc.text('Дансны №: 5529195414', 15, 55);
            doc.text(`Нэхэмжлэхийн №: ${invoiceNumber}`, 15, 61);

            doc.text(`Хариуцагч: ${localStorage.userName}`, 115, 25);
            doc.text(`Регистрийн №: ${localStorage.regNum}`, 115, 31);
            doc.text(`Хаяг: ${localStorage.userCityName}, ${localStorage.userSoumName}`, 115, 37);
            doc.text(`Утас: ${localStorage.phoneNumber}`, 115, 43);
            doc.text(`Фэйсбүүк нэр: ${localStorage.userFB}`, 115, 49);
            doc.text(`Нэхэмжилсэн огноо: ${new Date().toLocaleDateString()}`, 115, 55);
            doc.text(`Төлбөр төлөх огноо: ${new Date().toLocaleDateString()}`, 115, 61);  

            doc.autoTable({
                head: [[
                    {
                        content: '№',
                        rowSpan: 2,
                        styles: { halign: 'center', valign: 'middle' },
                    },
                    {
                        content: 'Барааны код',
                        rowSpan: 2,
                        styles: { halign: 'center', valign: 'middle' },
                    },
                    {
                        content: 'Барааны нэр',
                        rowSpan: 2,
                        styles: { halign: 'center', valign: 'middle' },
                    },
                    // {
                    //     content: 'Хэмжих нэгж',
                    //     rowSpan: 2,
                    //     styles: { halign: 'center', valign: 'middle' },
                    // },
                    {
                        content: 'Тоо ширхэг',
                        rowSpan: 2,
                        styles: { halign: 'center', valign: 'middle' },
                    },
                    {
                        content: 'Юань',
                        colSpan: 2,
                        styles: { halign: 'center', valign: 'middle' },
                    },
                    {
                        content: 'Төгрөг',
                        colSpan: 2,
                        styles: { halign: 'center', valign: 'middle' },
                    }
                ], [
                    {
                        content: 'Нэгж үнэ',
                        styles: { halign: 'center' },
                    },           
                    {
                        content: 'Нийт үнэ',
                        styles: { halign: 'center' },
                    },           
                    {
                        content: 'Нэгж үнэ',
                        styles: { halign: 'center' },
                    },           
                    {
                        content: 'Нийт үнэ',
                        styles: { halign: 'center' },
                    }
                ]],
                body: cart.map((item, index) => [
                    (index + 1).toString(),
                    item.pro_code,
                    item.pro_name,
                    // item.pro_unit,
                    item.quantity,
                    item.pro_price.toLocaleString(),
                    (Number(item.pro_price) * Number(item.quantity)).toLocaleString(),
                    (Number(item.pro_price) * Number(excRate)).toLocaleString(),
                    (Number(item.pro_price) * Number(item.quantity) * Number(excRate)).toLocaleString(),
                ]).concat([['', '', '', '', '', '', '', '']]),
                columnStyles: {
                    0 : { cellWidth: 10 },
                    1 : { cellWidth: 20 },
                    2 : { cellWidth: 56 },
                    // 3 : { cellWidth: 18 },
                    4 : { cellWidth: 18 },
                    5 : { cellWidth: 18 },
                    6 : { cellWidth: 18 },
                    7 : { cellWidth: 22 },
                    8 : { cellWidth: 22 }
                },
                margin: { top: 67 },
                styles: { font: "MAK Arial", halign: "center", valign:'middle', textColor: "#333" },
                headStyles: { fillColor: "#0771a6", font: "MAK Arial Bold", valign:'middle', textColor: '#fff' },
                didParseCell: (data) => {
                    if (data.section == 'body' && data.row.index == cart.length) {
                        data.cell.styles.halign = 'right';
                        data.cell.styles.font = 'MAK Arial Bold';
                        data.cell.colSpan = 8;
                        data.cell.text = ['Нийт үнэ: ' + totalPriceTk.toLocaleString() + '₮'];
                    }
                }
            });

            var image = new Image();
            image.src = stampImg;
            const YPosition = doc.autoTable.previous.finalY + 10;
            const imgWidth = 100;
            const imgHeight = 35;
            const imgCenter = (doc.internal.pageSize.width / 2) - (imgWidth / 2);
            doc.addImage(image, 'JPEG', imgCenter, YPosition, imgWidth, imgHeight);
            doc.save(`Нэхэмжлэл_${invoiceNumber}.pdf`);
            setLoading(false);
        } catch (error) {
            toast.error('Нэхэмжлэл үүсгэхэд алдаа гарлаа!');
        } finally {
            setLoading(false);
        }
    };

    const showDialogInv = () => {
        setLoading(true);
        setVisibleInv(true);
    }
    
    const cancelInv = () => setVisibleInv(false);

    const saveInvoiceItems = async () => {
        let isDone = false;
        try {
          const cartItems = await localForage.getItem('cart');
          if (cartItems) {
            await Promise.all(cartItems.map(async (item) => {
                const response = await PostRequest('invoice/createItems', {
                    invoice_number: invoiceNumber,
                    product_barCode: item.pro_code,
                    product_qty: item.quantity,
                    product_price: Number(item.pro_price) * Number(excRate),
                    invoice_date: format(new Date(), 'yyyy-M-dd')
                });
                return response;
            }));
            isDone = true;
          }
        } catch (error) {
            toast.error('Нэхэмжлэлийн барааг баазад хадгалах явцад алдаа гарлаа!' + error);
        }
      
        if (isDone) {
          generateInvoicePDF();
          toast.success('Нэхэмжлэл хадгалагдлаа!');
          setVisibleInv(false);
          await localForage.removeItem('cart'); 
          localStorage.setItem('cartLength', 0);
          window.location.href = "/invoice";
        }
      };

    const confirmInv = async() => {
        try {
            const response = await PostRequest('invoice/create', {
                inv_number: invoiceNumber,
                inv_user: Number(localStorage.userId),
                inv_date: format(new Date(), 'yyyy-M-dd'),
                inv_items_cnt: Number(cart.length),
                inv_price: Number(totalPriceTk)
            });
            if (response)
                saveInvoiceItems();
        } catch (error) {
            toast.error('Нэхэмжлэлийн мэдээллийг баазад хадгалах явцад алдаа гарлаа!' + error);
        }
    };

    useEffect(() => {
        const fetchCart = async () => {
            try {
                const cartData = await localForage.getItem('cart');
                if (cartData)
                    setCart(cartData);
            } catch (error) {
                console.error('Error fetching cart data:', error);
                toast.error('Сагсны мэдээлэл авахад алдаа гарлаа!');
            }
          };
      
        fetchCart();

        const generateInvoiceNumber = () => {
            const currentDate = new Date();
            const year = currentDate.getFullYear().toString().slice(2);
            const month = String(currentDate.getMonth() + 1).padStart(2, '0');
            const day = String(currentDate.getDate()).padStart(2, '0');
            const randomDigits = Math.floor(1000 + Math.random() * 9000).toString();
        
            setInvoiceNumber(year + month + day + randomDigits);
        }

        generateInvoiceNumber();
    }, []);

    return (
        <section className="cart py-40">
            <Toaster position="top-right" />
            <div className="container container-lg">
                <div className="row gy-4">
                    <div className="col-xl-9 col-lg-8">
                        <div className="cart-table border border-gray-100 rounded-8 px-20 py-28">
                            <div className="overflow-x-auto scroll-sm scroll-sm-horizontal">
                                <table className="table style-two">
                                    <thead>
                                        <tr>
                                            <th className="mb-0 text-sm fw-medium text-center">№</th>
                                            <th className="mb-0 text-sm fw-medium text-center">Бараа</th>
                                            <th className="mb-0 text-sm fw-medium text-center">Нэгж үнэ</th>
                                            <th className="mb-0 text-sm fw-medium text-center">Тоо ширхэг</th>
                                            <th className="mb-0 text-sm fw-medium text-center">Нийт үнэ</th>
                                            <th className="mb-0 text-sm fw-medium text-center">Устгах</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            cart.length > 0 ? cart.map((item, index) => (
                                                <tr>
                                                    <td className="text-center">
                                                        <span className="text-sm mb-0">{index + 1}</span>
                                                    </td>
                                                    <td>
                                                        <div className="table-product d-flex align-items-center gap-24" style={{minWidth: '100px'}}>
                                                            <Link to="/product-details-two" className="table-product__thumb border rounded-8 flex-center">
                                                                <img src={'https://ingumel.mn/backend/' + item.pro_image} />
                                                            </Link>
                                                            <div className="table-product__content text-start">
                                                                <span className="text-sm mb-0">{item.pro_name}</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span className="text-sm mb-0">{item.pro_price + '¥ / '+  (item.pro_price * excRate).toLocaleString() + '₮'}</span>
                                                    </td>
                                                    <td>
                                                        <div className="mb-32">
                                                            <label
                                                                htmlFor="stock"
                                                                className="text-sm mb-8 text-heading fw-semibold d-block">
                                                                Тоо ширхэг:
                                                            </label>
                                                            <div className="d-flex rounded-4 overflow-hidden">
                                                                <button onClick={() => decrementQuantity(item.id, item.order_cnt)} type="button"
                                                                    className="quantity__minus flex-shrink-0 h-48 w-48 text-neutral-600 bg-gray-50 flex-center hover-bg-main-600 hover-text-white"
                                                                >
                                                                    <i className="ph ph-minus" />
                                                                </button>
                                                                <input
                                                                    type="number"
                                                                    className="quantity__input flex-grow-1 border border-gray-100 border-start-0 border-end-0 text-center w-40 text-sm"
                                                                    id="stock"
                                                                    value={parseInt(item.quantity)} readOnly/>
                                                                <button onClick={() => incrementQuantity(item.id, item.order_cnt)} type="button"
                                                                    className="quantity__plus flex-shrink-0 h-48 w-48 text-neutral-600 bg-gray-50 flex-center hover-bg-main-600 hover-text-white"
                                                                >
                                                                    <i className="ph ph-plus" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span className="text-sm mb-0">{parseInt(item.pro_price * item.quantity) + '¥ / '+  (parseInt(item.pro_price * item.quantity * excRate)).toLocaleString() + '₮'}</span>
                                                    </td>
                                                    <td className="text-center">
                                                        <button type="button" className="remove-tr-btn" onClick={() => showDialog(item.id)}>
                                                            <i className="pi pi-trash text-md d-flex text-danger text-center" />
                                                        </button>
                                                    </td>
                                                </tr>
                                            )) :
                                            (
                                                <tr>
                                                    <td colSpan={6} className="text-center">
                                                        <span className="text-md mb-0 fw-semibold">Сагс хоосон байна!</span>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4">
                        <div className="cart-sidebar border border-gray-100 rounded-8 px-24 py-40">
                            <h6 className="text-md mb-20 fw-medium">Нэхэмжлэхийн мэдээлэл</h6>
                            <div className="bg-color-three rounded-8 p-24 mt-20">
                                <div className="flex-between gap-8">
                                    <span className="text-gray-900 text-sm fw-medium">Нэхэмжлэхийн №:</span>
                                    <span className="text-gray-900 text-sm fw-medium">{invoiceNumber}</span>
                                </div>
                                <div className="flex-between gap-8 mt-20">
                                    <span className="text-gray-900 text-sm fw-medium">Хариуцагч:</span>
                                    <span className="text-gray-900 text-sm fw-medium">{localStorage?.userName}</span>
                                </div>
                                <div className="flex-between gap-8 mt-20">
                                    <span className="text-gray-900 text-sm fw-medium">РД:</span>
                                    <span className="text-gray-900 text-sm fw-medium">{localStorage?.regNum}</span>
                                </div>
                                <div className="flex-between gap-8 mt-20">
                                    <span className="text-gray-900 text-sm fw-medium">Утас:</span>
                                    <span className="text-gray-900 text-sm fw-medium">{localStorage?.phoneNumber.substr(0, 4) + '-' + localStorage?.phoneNumber.substr(4, 9)}</span>
                                </div>
                                <div className="flex-between gap-8 mt-20">
                                    <span className="text-gray-900 text-sm fw-medium">Хаяг:</span>
                                    <span className="text-gray-900 text-sm fw-medium">{localStorage?.userCityName +', '+localStorage?.userSoumName}</span>
                                </div>
                            </div>
                            <h6 className="text-md mb-20 fw-medium mt-20">Сагсны мөнгөн дүн</h6>
                            <div className="bg-color-three rounded-8 p-24 mt-20">
                                <div className="flex-between gap-8">
                                    <span className="text-gray-900 text-sm fw-medium">Нийт бараа:</span>
                                    <span className="text-gray-900 text-sm fw-medium">{cart.length}</span>
                                </div>
                                <div className="flex-between gap-8 mt-20">
                                    <span className="text-gray-900 text-sm fw-medium">Нийт үнэ /юань/:</span>
                                    <span className="text-gray-900 text-sm fw-medium">{totalPriceYn.toLocaleString()}¥</span>
                                </div>
                                <div className="flex-between gap-8 mt-20">
                                    <span className="text-gray-900 text-sm fw-medium">Нийт үнэ /төгрөг/:</span>
                                    <span className="text-gray-900 text-sm fw-medium">{totalPriceTk.toLocaleString()}₮</span>
                                </div>
                            </div>
                            <Button onClick={() => showDialogInv()} 
                                disabled={cart.length == 0 || loading}
                                className="btn btn-main mt-40 py-18 w-100 rounded-8 text-sm" style={{textTransform: 'none'}}>
                                <i className={loading ? 'pi pi-sync' : 'pi pi-download'}/>&nbsp;{loading ? 'Ачаалж байна' : 'Нэхэмжлэл татаж авах'}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog open={visible} onClose={() => setVisible(false)}>
                <DialogTitle style={{color: '#0771a6', fontWeight: 'bold'}} className="text-md">Анхаар!</DialogTitle>
                    <DialogContent>
                    <DialogContentText className='text-sm'><span className='pi pi-info-circle' style={{color: '#0771a6'}}></span>{" "}Та энэ барааг сагснаас хасахыг хүсч байна уу?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className='btn-main text-white text-xs' onClick={cancel}><span className='pi pi-times'></span>&nbsp;Үгүй</Button>
                    <Button className='btn-danger text-xs' style={{color: '#fff', background: '#ff0000'}} onClick={confirm}><span className='pi pi-check'></span>&nbsp;Тийм</Button>
                </DialogActions>
            </Dialog>
            
            <Dialog open={visibleInv} onClose={() => setVisibleInv(false)}>
                <DialogTitle style={{color: '#ff0000', fontWeight: 'bold'}} className="text-lg text-center">АНХААР!</DialogTitle>
                    <DialogContent>
                    <DialogContentText className='text-sm text-center'>Та нэхэмжлэл үүсгэснээр өөрийн сонгосон барааны жагсаалтыг дахин засварлах боломжгүй болохыг анхаарна уу!</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className='btn-main text-white text-xs' onClick={cancelInv}><span className='pi pi-sync'></span>&nbsp;Дахин нягтлах</Button>
                    <Button className='btn-danger text-xs' style={{color: '#fff', background: '#ff0000'}} onClick={confirmInv}><span className='pi pi-download'></span>&nbsp;Татаж авах</Button>
                </DialogActions>
            </Dialog>
        </section>
    )
}

export default CartSection