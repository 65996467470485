import React, { useEffect, useReducer, useMemo } from 'react';
import Slider from 'react-slick';
import localForage from 'localforage';
import toast, { Toaster } from 'react-hot-toast';
import { PostRequest } from "../helper/ApiConfig";
import PuffLoader from "react-spinners/PuffLoader";
import { Link, useParams } from 'react-router-dom';
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';

const initialState = {
    productInfo: null,
    proQty: 0,
    exchRate: 0,
    loading: true,
    relatedProducts: [],
    cart: [],
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_PRODUCT_INFO':
            return { ...state, productInfo: action.payload, loading: false };
        case 'SET_EXCH_RATE':
            return { ...state, exchRate: action.payload };
        case 'SET_PRO_QTY':
            return { ...state, proQty: action.payload };
        case 'SET_RELATED_PRODUCTS':
            return { ...state, relatedProducts: action.payload, loading: false };
        case 'UPDATE_CART':
            return { ...state, cart: action.payload };
        case 'SET_LOADING':
            return { ...state, loading: action.payload };
        default:
            return state;
    }
};

const RelatedProductItem = React.memo(({ pro, exchRate }) => (
    <div className="vendors-two-item rounded-12 overflow-hidden bg-color-three border border-neutral-50 hover-border-main-600 transition-2">
        <div className='vendors-two-item__top bg-overlay style-two position-relative'>
            <div className='vendors-two-item__thumbs' style={{ height: '13rem' }}>
                <img src={'https://ingumel.mn/backend/' + pro.pro_image} className="cover-img" loading="lazy" alt={pro.pro_name} />
            </div>
            <div className='position-absolute top-0 inset-inline-start-0 w-100 h-100 p-24 z-1 d-flex flex-column justify-content-between'>
                <div className='d-flex align-items-center justify-content-between'></div>
                <div className='mt-16'>
                    <h6 className='text-white fw-semibold mb-12'>
                        <Link to={"/product/" + pro.pro_code} className="text-line-2 text-md hover-text-white">
                            {pro.pro_name}
                        </Link>
                    </h6>
                </div>
            </div>
        </div>
        <div className="vendors-two-item__content p-24 flex-grow-1">
            <div className="d-flex flex-column gap-14">
                <div className="flex-align gap-8">
                    <span className="flex-center text-main-600 text-xl flex-shrink-0">
                        <i className="pi pi-th-large" />
                    </span>
                    <span className="text-gray-900" style={{ fontSize: '13px' }}>
                        {pro.cat_name}
                    </span>
                </div>
                <div className="flex-align gap-8">
                    <span className="flex-center text-main-600 text-xl flex-shrink-0">
                        <i className="pi pi-hashtag" />
                    </span>
                    <span className="text-gray-900" style={{ fontSize: '13px' }}>
                        Код: {pro.pro_code}
                    </span>
                </div>
                <div className="flex-align gap-8">
                    <span className="flex-center text-main-600 text-xl flex-shrink-0">
                        <i className="pi pi-money-bill" />
                    </span>
                    <span className="text-gray-900" style={{ fontSize: '13px' }}>
                        Үнэ: ¥{pro.pro_price}
                        <span>&nbsp;/&nbsp;{parseInt(pro.pro_price * parseInt(exchRate))}₮</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
));

const ProductDetails = ({ addToCartHeader }) => {
    const { productCode } = useParams();
    const [ state, dispatch ] = useReducer(reducer, initialState);
    const { productInfo, proQty, exchRate, relatedProducts, cart, loading } = state;

    function SampleNextArrow(props) {
        const { className, onClick } = props;
        return (
            <button
                type="button" onClick={onClick}
                className={` ${className} slick-next slick-arrow flex-center rounded-circle border border-gray-100 hover-border-main-600 text-xl hover-bg-main-600 hover-text-white transition-1`}>
                <i className="ph ph-caret-right" />
            </button>
        );
    }

    function SamplePrevArrow(props) {
        const { className, onClick } = props;

        return (

            <button type="button" onClick={onClick}
                className={`${className} slick-prev slick-arrow flex-center rounded-circle border border-gray-100 hover-border-main-600 text-xl hover-bg-main-600 hover-text-white transition-1`}>
                <i className="ph ph-caret-left" />
            </button>
        );
    }
    
    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 6,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1599,
                settings: {
                    slidesToShow: 6
                },
            },
            {
                breakpoint: 1399,
                settings: {
                    slidesToShow: 4
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3
                },
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 2
                },
            },
            {
                breakpoint: 424,
                settings: {
                    slidesToShow: 1
                },
            },

        ],
    };

    const goBack = () => {
        if (window.history.length > 1) 
            window.history.back();
        else if (document.referrer) 
            window.location.href = document.referrer;
        else 
            window.location.href = "/";
    };

    // Memoized calculations for prices
    const totalPrice = useMemo(() => {
        if (!productInfo) return 0;
        return parseInt(productInfo.pro_price * proQty);
    }, [productInfo, proQty]);

    const totalPriceMNT = useMemo(() => {
        if (!productInfo || !exchRate) return 0;
        return parseInt(productInfo.pro_price * proQty * parseInt(exchRate));
    }, [productInfo, proQty, exchRate]);

    // Handle quantity changes
    const handleQuantityChange = (type) => {
        const orderCount = Number(productInfo?.order_cnt) || 0;
        let newQty = Number(proQty);

        if (type == 'increment')
            newQty += orderCount;
        else if (type == 'decrement')
            newQty = Math.max(orderCount, newQty - orderCount);

        dispatch({ type: 'SET_PRO_QTY', payload: newQty });
    };

    // Add item to cart
    const addToCart = (item) => {
        const existingItemIndex = cart.findIndex(cartItem => cartItem.id == item.id);
        const updatedCart = [...cart];
      
        if (existingItemIndex > -1) {
            updatedCart[existingItemIndex].quantity += proQty;
        
            localForage.setItem('cart', updatedCart).then(() => {
                dispatch({ type: 'UPDATE_CART', payload: updatedCart });
                localStorage.setItem('cartLength', updatedCart.length);
                localStorage.setItem('excRate', exchRate);
                toast.success(`${item.pro_name}-с ${proQty} ширхэг сагсанд нэмэгдлээ!`);
            }).catch((err) => {
                console.error('Error adding item to cart:', err);
            });
        }
          
        else {
            const newItem = {
                id: item.id, 
                pro_name: item.pro_name,
                pro_code: item.pro_code,
                pro_image: item.pro_image,
                pro_price: item.pro_price,
                order_cnt: item.order_cnt,
                quantity: proQty
            };
            updatedCart.push(newItem);
        
            localForage.setItem('cart', updatedCart).then(() => {
                dispatch({ type: 'UPDATE_CART', payload: updatedCart });
                localStorage.setItem('cartLength', updatedCart.length);
                localStorage.setItem('excRate', exchRate);
                toast.success(`${item.pro_name}-с ${proQty} ширхэг сагсанд нэмэгдлээ!`);
                addToCartHeader();
            }).catch((err) => {
                console.error('Error adding item to cart:', err);
            });
        }
    };

    // Fetch product details and related products
    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            try {
                dispatch({ type: 'SET_LOADING', payload: true });

                const productResponse = await PostRequest('product/detail', { pro_id: null, pro_code: productCode });

                if(productResponse) {
                    const relatedResponse = await PostRequest('product/col', { col: productResponse.product_info.pro_category });
    
                    if (isMounted) {
                        dispatch({ type: 'SET_PRODUCT_INFO', payload: productResponse.product_info });
                        dispatch({ type: 'SET_EXCH_RATE', payload: productResponse.exchangeRate });
                        dispatch({ type: 'SET_PRO_QTY', payload: productResponse.product_info.order_cnt });
                        dispatch({ type: 'SET_RELATED_PRODUCTS', payload: relatedResponse.product_info });
                        dispatch({ type: 'SET_LOADING', payload: false });
                    }
                }
            } catch (error) {
                if (isMounted) toast.error('Барааны мэдээлэл татах явцад алдаа гарлаа!' + error);
                dispatch({ type: 'SET_LOADING', payload: false });
            }
        };

        fetchData();

        const fetchCart = async () => {
            try {
                const cartData = await localForage.getItem('cart');
                if (cartData)
                    dispatch({ type: 'UPDATE_CART', payload: cartData });
            } catch (error) {
                console.error('Error fetching cart data:', error);
                toast.error('Сагсны мэдээлэл авахад алдаа гарлаа!');
            }
        };
    
        fetchCart();

        return () => {
            isMounted = false;
        };
    }, [productCode]);

    return (
        <>
        <section className="product-details py-40">
            <Toaster position="top-right" />
            <div className="container container-lg">
            {
                loading ? (
                    <div className="row gy-4 flex-center" style={{ height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <PuffLoader color='#0771a6' loading={loading} size={50} aria-label="Уншиж байна..." data-testid="loader" />
                    </div>      
                ) : (
                <div className="row gy-4">
                    <div className="text-end">
                        <button onClick={() => goBack()} className="btn-white text-main" style={{paddingRight: '10px'}}>&#171;&nbsp;Буцах</button>
                    </div>
                    <div className="col-xl-9">
                        <div className="row gy-4">
                            <div className="col-xl-6">
                                <div className="product-details">
                                    <div className="product-details__thumb-slider border border-gray-100 rounded-16">
                                        <div className="product-details__thumb flex-center">
                                            <InnerImageZoom src={'https://ingumel.mn/backend/' + productInfo?.pro_image} className='max-h-400 rounded-8' loading='lazy'/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="product-details__content">
                                    <h5 className="mb-12 text-xl">{productInfo?.pro_name}</h5>
                                    <div className="flex-align flex-wrap gap-12">
                                        <span className="text-gray-500">
                                            <span className="text-gray-900">Барааны код:&nbsp;</span>{productInfo?.pro_code}
                                        </span>
                                        <span className="text-sm fw-medium text-gray-500">|</span>
                                        <span className="text-gray-500">
                                            <span className="text-gray-900">Барааны баркод:&nbsp;</span>{productInfo?.pro_barCode || '-'}
                                        </span>
                                    </div>
                                    <span className="mt-32 pt-32 text-gray-700 border-top border-gray-100 d-block" />
                                    <div className="mb-40">
                                        <h6 className="mb-24 text-lg">Барааны танилцуулга</h6>
                                        <ul className="mt-32">
                                            <li className="text-gray-400 mb-14 flex-align gap-14">
                                                <span className="w-20 h-20 bg-main-50 text-gray-500 text-xs flex-center rounded-circle">
                                                    <i className="ph ph-check" />
                                                </span>
                                                <span className="text-heading fw-medium">
                                                    Ангилал:&nbsp;<span className="text-gray-500">{productInfo?.cat_name || '-'}</span>
                                                </span>
                                            </li>
                                            <li className="text-gray-400 mb-14 flex-align gap-14">
                                                <span className="w-20 h-20 bg-main-50 text-gray-500 text-xs flex-center rounded-circle">
                                                    <i className="ph ph-check" />
                                                </span>
                                                <span className="text-heading fw-medium">
                                                    Өнгөний сонголт:&nbsp;<span className="text-gray-500">{productInfo?.pro_color ? productInfo.pro_color : '-'}</span>
                                                </span>
                                            </li>
                                            <li className="text-gray-400 mb-14 flex-align gap-14">
                                                <span className="w-20 h-20 bg-main-50 text-gray-500 text-xs flex-center rounded-circle">
                                                    <i className="ph ph-check" />
                                                </span>
                                                <span className="text-heading fw-medium">
                                                    Размер/хэмжээ:&nbsp;<span className="text-gray-500">{productInfo?.pro_size ? productInfo.pro_size : '-'}</span>
                                                </span>
                                            </li>
                                            <li className="text-gray-400 mb-14 flex-align gap-14">
                                                <span className="w-20 h-20 bg-main-50 text-gray-500 text-xs flex-center rounded-circle">
                                                    <i className="ph ph-check" />
                                                </span>
                                                <span className="text-heading fw-medium">
                                                    Хайрцаг доторх тоо:&nbsp;<span className="text-gray-500">{productInfo?.pro_package_cnt}</span>
                                                </span>
                                            </li>
                                            <li className="text-gray-400 mb-14 flex-align gap-14">
                                                <span className="w-20 h-20 bg-main-50 text-gray-500 text-xs flex-center rounded-circle">
                                                    <i className="ph ph-check" />
                                                </span>
                                                <span className="text-heading fw-medium">
                                                    Захиалах хамгийн доод тоо:&nbsp;<span className="text-gray-500">{productInfo?.order_cnt}</span>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3">
                        <div className="product-details__sidebar py-40 px-32 border border-gray-100 rounded-16">
                            <div className="mb-32">
                                <label
                                    htmlFor="stock"
                                    className="text-md mb-8 text-heading fw-semibold d-block">
                                    Тоо ширхэг:
                                </label>
                                <div className="d-flex rounded-4 overflow-hidden">
                                    <button onClick={() => handleQuantityChange('decrement')} type="button"
                                        className="quantity__minus flex-shrink-0 h-48 w-48 text-neutral-600 bg-gray-50 flex-center hover-bg-main-600 hover-text-white"
                                    >
                                        <i className="ph ph-minus" />
                                    </button>
                                    <input
                                        type="number"
                                        className="quantity__input flex-grow-1 border border-gray-100 border-start-0 border-end-0 text-center w-32 px-16"
                                        id="stock"
                                        value={proQty} readOnly/>
                                    <button onClick={() => handleQuantityChange('increment')} type="button"
                                        className="quantity__plus flex-shrink-0 h-48 w-48 text-neutral-600 bg-gray-50 flex-center hover-bg-main-600 hover-text-white"
                                    >
                                        <i className="ph ph-plus" />
                                    </button>
                                </div>
                            </div>
                            <div className="mb-32">
                                <div className="flex-between flex-wrap gap-8 border-bottom border-gray-100 pb-16 mb-16">
                                    <span className="text-gray-500 text-sm">Нэгж үнэ:</span>
                                    <h6 className="text-md mb-0">¥{productInfo?.pro_price}</h6>
                                    <span className="text-gray-500 fw-normal text-sm"> / {parseInt(productInfo?.pro_price * parseInt(exchRate))}₮</span>{" "}

                                </div>
                                <div className="flex-between flex-wrap gap-8">
                                    <span className="text-gray-500 text-sm">Нийт үнэ:</span>
                                    <h6 className="text-md mb-0">¥{totalPrice}</h6>
                                    <span className="text-gray-500 fw-normal text-sm"> / {totalPriceMNT}₮</span>{" "}
                                </div>
                            </div>
                            <Link onClick={() => addToCart(productInfo)} className="btn btn-main flex-center gap-8 rounded-8 py-16 fw-normal mt-48">
                                <i className="ph ph-shopping-cart-simple text-md" />Сагсанд нэмэх
                            </Link>
                        </div>
                    </div>
                </div>
                )
            }
            </div>
        </section>
        <section className="new-arrival">
            <div className="container container-lg">
                <div className="section-heading">
                    <div className="flex-between flex-wrap gap-8">
                        <h5 className="mb-0 text-xl">Төстэй бараанууд</h5>
                        <div className="flex-align mr-point gap-16">
                            {
                                productInfo && (
                                    <Link to={"/shop/" + productInfo.pro_category + '/all/all'} className="text-sm fw-medium text-gray-700 hover-text-main-600">
                                        Бүх төстэй бараануудыг харах
                                    </Link>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="new-arrival__slider arrow-style">
                    <Slider {...settings}>
                        {relatedProducts.map((pro) => (
                            <RelatedProductItem key={pro.pro_code} pro={pro} exchRate={exchRate} />
                        ))}
                    </Slider>
                </div>
            </div>
        </section>
        </>
    )
}

export default ProductDetails