import React from 'react';
import { Link } from 'react-router-dom';
import './bottom-nav.css';

const BottomNav = () => {
    return (
        <nav className='bottom-nav'>
            <ul className="flex justify-around">
                <li>
                    <Link to="/" className="nav-link">
                        <i className="pi pi-home text-xl" />
                    </Link>
                </li>
                <li>
                    <Link to="/category" className="nav-link">
                        <i className="pi pi-th-large text-xl" />
                    </Link>
                </li>
                <li>
                    <Link to="/cart" className="nav-link">
                        <i className="pi pi-shopping-cart text-xl" />
                    </Link>
                </li>
                <li> 
                    <Link to="/account" className="nav-link">
                        <i className="pi pi-user text-xl" />
                    </Link>
                </li>
                <li> 
                    <Link to="/invoice" className="nav-link">
                        <i className="pi pi-receipt text-xl" />
                    </Link>
                </li>
            </ul>
        </nav>
    );
};

export default BottomNav;